// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { notification } from 'antd';

// const BASE_URL = 'http://127.0.0.1:8787'
const BASE_URL = 'https://chat-api.linyucong.me'
const api: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    // baseURL: 'https://chat-api.linyucong.me',
    timeout: 1000000,
    headers: {
        'Content-Type': 'application/json',
    }
});

api.interceptors.request.use(
    async (config) => {
        // add token to the header
        if (localStorage.getItem('token')) {
            let token;
            try{
                token = JSON.parse(localStorage.getItem('token') as string);
            }catch (error){
                localStorage.removeItem('token');
                window.location.href = '/login';
            }

            if (token) {
                let now = new Date();
                let expiry = new Date(token.expiry);
                now.setHours(now.getHours() - 1);

                if (now >= expiry) {
                    try {
                        // Make an API request to refresh the token
                        const response = await axios.post(BASE_URL+'/refresh_token', {
                            token: token.token,
                        });
                        // console.log('Token refreshed:', response.data);

                        // Update the token in localStorage with the new token
                        localStorage.setItem('token', JSON.stringify(response.data));

                        // Update the Authorization header with the new token
                        config.headers.Authorization = `${response.data.token}`;
                    } catch (error) {
                        // Handle token refresh error
                        console.error('Failed to refresh token:', error);
                        // You may want to log out the user or take appropriate action
                    }
                } else {
                    // Token is still valid, set the Authorization header
                    config.headers.Authorization = `${token.token}`;
                }
            }

        }

        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => {
        // Any status code that lies within the range of 2xx causes this function to trigger
        // Do something with response data
        return response;
    },
    (error) => {
        console.log(error);
        if(error.response?.status === 401){
            notification.open({
                type: 'error',
                message: 'Invalid Token',
                description:
                    'Your token is invalid( possibly because of system upgrades ), please click me to log in again.',
                onClick: () => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                },
                onClose: () => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
            });
            return Promise.resolve(error);
        }
        notification.open({
            type: 'error',
            message: 'Server Error',
            description:
                'There was an error connecting to the server, please try again later.',
        });

        return Promise.resolve(error);

    }
);

// GET request
export const getData = async (endpoint: string): Promise<AxiosResponse> => {
    try {
        const response: AxiosResponse = await api.get(endpoint);
        return response.data;
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

// POST request
export const postData = async (endpoint: string, data: object): Promise<AxiosResponse> => {
    try {
        const token = localStorage.getItem('token');
        let response: AxiosResponse;
        if (!token) {
            response = await axios.post(BASE_URL + '/' +endpoint, data);
        }else{
            response = await api.post(endpoint, data);
        }
        return response.data;
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export const postFormData = async (endpoint: string, data: FormData): Promise<AxiosResponse> => {
    try {
        let response: AxiosResponse;
        response = await api.post(endpoint, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }

        });
        return response.data;
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};
