import React, {useEffect, useRef, useState} from 'react';
import { getChatResponse, getAChatConversation, messageThread } from '../../../axios/chatService';
import ChatContent from "./chatContent";
import ChatBox from "./chatBox";
import './chatSide.css';

interface Props {
    id: string | undefined;
}

const ChatSide: React.FC<Props> = ({ id }) => {
    const [data, setData] = useState<getChatResponse | null>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Scroll to the bottom of the chat container whenever the component updates
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    });

    useEffect(() => {
        console.log(id);
        if (id) {
            getAChatConversation(id).then((result) => {
                console.log(result);
                setData(result);
            });
        }
    }, [id]);

    // const startChat = async () => {
    //     try {
    //         const result = await createChat();
    //         window.location.href = '/chat/' + result.chat_id;
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const addMessage = (newData: messageThread[]) => {
        if (!data) {
            return;
        }
        // if (update) {
        //     let oldData = data?.conversation;
        //     oldData[oldData.length - 1] = newData;
        //     setData({ conversation: oldData, chat_id: data.chat_id, type: data.type });
        //     return;
        // }
        // let oldData = data?.conversation;
        // oldData?.push(newData);
        // setData({ conversation: oldData, chat_id: data.chat_id, type: data.type });
        setData({ conversation: newData, chat_id: data.chat_id, type: data.type })
    };

    const getMessage = () => {
        if (!data) {
            return [];
        }
        return data.conversation;
    };

    return (
        <div style={{ height: '100%' }}>
            {id ? (
                <div className='container'>
                    <div ref={chatContainerRef} className='upper-box'>
                        <ChatContent data={data} />
                    </div>
                    <div className='lower-box'>
                        <ChatBox id={id} model={data?.type} updateData={addMessage} getMessage={getMessage} />
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', justifyContent: 'center' }}>
                    {/*<Button type="primary" onClick={startChat}>*/}
                    {/*    Start a chat*/}
                    {/*</Button>*/}
                </div>
            )}
        </div>
    );
};

export default ChatSide;
