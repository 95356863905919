import React, { useEffect, useState } from 'react';
import { chat, createChat, getAllChats } from '../../../axios/chatService';
import { Button, Select, Card } from 'antd';
import './chatHistory.css';
const { Option } = Select;
const ChatHistory: React.FC = () => {
    const [data, setData] = useState<Array<chat> | null>(null);
    const [model, setModel] = useState<string>('claude-3-opus-20240229');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await getAllChats(1);
        console.log(result);
        setData(result);
    };

    const startChat = async () => {
        try {
            const result = await createChat(model);
            window.location.href = '/chat/' + result.chat_id;
        } catch (e) {
            console.log(e);
        }
    };

    const formatTime = (time: string) => {
        const date = new Date(time);
        return date.toLocaleString();
    };

    const getContent = (item: chat) => {
        if (item.conversation === null || item.conversation.length === 0 || item.conversation[0] === null) {
            return '';
        }
        if (typeof item.conversation[0].content === 'string') {
            return item.conversation[0].content;
        } else {
            return item.conversation[0].content[0].text;
        }
    };

    const onModelChange = (value: string) => {
        setModel(value);
    };

    return (
        <div>
            <div
                style={{ height: 100, width: '96%', margin: '2%' }}
            >
                <Button
                    type="primary"
                    onClick={startChat}
                    style={{ width: '96%', margin: '1% 2%' }}
                >
                    Start New Chat
                </Button>
                    <Select
                        placeholder="Select a model"
                        onChange={onModelChange}
                        // allowClear
                        defaultValue={'claude-3-opus-20240229'}
                        style={{ width: '96%', margin: '1% 2%' }}
                    >
                        <Option  value="claude-3-opus-20240229">claude-3-opus</Option>
                        <Option value="claude-3-sonnet-20240229">claude-3-sonnet</Option>
                        <Option value="claude-3-haiku-20240307">claude-3-haiku</Option>
                        <Option value="gpt-4-turbo">gpt-4-turbo</Option>
                        <Option value="gpt-4">gpt-4</Option>
                        <Option value="gpt-3.5-turbo">gpt-3.5-turbo</Option>
                        <Option value="gemini-1.5-pro">gemini-1.5-pro</Option>

                    </Select>

            </div>

            {data?.map((item, index) => (
                <Card
                    key={index}
                    size="small"
                    title={'Type: ' + item.type}
                    extra={<a href={'/chat/' + item.chat_id}>Continue</a>}
                    style={{ width: '96%', margin: '2%', backgroundColor: '#eaf7ff' }}
                >
                    <p className="truncate-overflow">{getContent(item)}</p>
                    <h5 className="truncate-overflow">Last Updated: {formatTime(item.last_updated)}</h5>
                </Card>
            ))}
        </div>
    );
};

export default ChatHistory;
