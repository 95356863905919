import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import Chat from './pages/chat/chat';
import Login from "./pages/login/login";
import Register from "./pages/register/register";

interface ProtectedRouteProps {
    component: React.ComponentType<any>;
    [key: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
    const isAuthenticated = () => {
        // Check if the token exists in local storage
        const token = localStorage.getItem('token');
        return token !== null;
    };

    return isAuthenticated() ? <Component {...rest} /> : <Navigate to="/login" replace />;
};

const router = createBrowserRouter([
    // redirect / to /chat
    {
        path: '/',
        element: <Navigate to="/chat" />,
    },
    {
        path: '/chat/:id?',
        element: <ProtectedRoute component={Chat} />,
    },
    {
        path: '/login',
        element: <Login/>,
    },
    {
        path: '/register',
        element: <Register/>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();