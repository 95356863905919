import React from 'react';
import {getChatResponse, messageThread} from '../../../axios/chatService'
import {Avatar, List} from 'antd';
import MarkdownPreview from '@uiw/react-markdown-preview';

interface Props {
    data: getChatResponse | null;
}

interface State {
    data: getChatResponse | null;
}

class ChatContent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { data: null };
    }

    componentDidMount() {
        console.log("componentDidMount",this.props.data);
    }

    // componentDidUpdate(prevProps: Props) {
    //     console.log("componentDidUpdate",this.props.data);
    // }

    componentWillUnmount() {
    }

    async fetchData() {
        // Simulating an API call
        // setTimeout(() => {
        //     this.setState({ data: `Data for ID: ${this.props.id}` });
        // }, 1000);
        // const result = await getAllChats()
        // console.log(result)

    }

    concatText(type: string | undefined, item: messageThread){

        if(typeof item.content === 'string'){
            return item.content
        }

        let result = ''
        for (let i = 0; i < item.content.length; i++) {
            // @ts-ignore
            if(item.content[i].type === 'text'){
                // @ts-ignore
                result += item.content[i].text
            }else { // @ts-ignore
                if (item.content[i].type === 'image'){
                    // @ts-ignore
                    result += `![Image](data:${item.content[i].source?.media_type};base64,${item.content[i].source?.data})`
                }
                // @ts-ignore
                if (item.content[i].type === 'image_url') {
                    // @ts-ignore
                    result += `![Image](${item.content[i].image_url.url})`
                }
            }
        }
        return result
    }

    render() {
        return <div>
            <List
                itemLayout="horizontal"
                dataSource={this.props.data?.conversation}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={item.role === 'user' ? 'https://api.dicebear.com/8.x/pixel-art/svg' :'https://api.dicebear.com/8.x/bottts/svg'} />}
                            description={<MarkdownPreview source={this.concatText(this.props.data?.type, item)} style={{ padding: 16, borderRadius:'10px' }}  />}/>
                    </List.Item>
                )}
            />
        </div>;
    }
}

export default ChatContent;