import React, { useState } from 'react';
import { messageItem, messageThread, uploadFile} from '../../../axios/chatService';
import { Input, message, Space, Spin } from 'antd';

const { TextArea } = Input;

interface Props {
    id: string | undefined;
    model: string | undefined;
    updateData: (newData: messageThread[]) => void;
    getMessage: () => messageThread[];
}

interface streamData {
    content:string | null,
    stop:boolean | null
}

const ChatBox: React.FC<Props> = ({ id, updateData, model, getMessage }) => {
    const [userInput, setUserInput] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<File[] | null>([]);

    const startConnection = async () => {
        // Create a new WebSocket connection
        const newSocket = new WebSocket('wss://chat-api.linyucong.me/send_message');

        // Set the socket state when the connection is opened
        newSocket.onopen = () => {
            let thread = getMessage();
            newSocket.send(JSON.stringify({ message: thread, model: model, chat_id: id }));
            let messageThread: messageThread = {
                role: model?.includes('gpt') ? 'system' : 'assistant',
                content: "",
            };
            thread.push(messageThread);
            updateData(thread);

            console.log('WebSocket connection established');
        };

        // Handle incoming messages
        newSocket.onmessage = (event: MessageEvent) => {
            const message = JSON.parse(event.data) as streamData;
            // console.log('Received message:', event.data);
            let thread = getMessage();

            if (message.content){
                let last = thread[thread.length - 1];
                last.content = last.content + message.content;
                thread[thread.length - 1] = last;
                updateData(thread);
            }else if (message.stop){
                setUserInput('');
                setFiles([]);
                setLoading(false);
                newSocket.close();
            }
        };

        // Handle connection close
        newSocket.onclose = () => {
            console.log('WebSocket connection closed');
            newSocket.close();
        };

        // Handle connection error
        newSocket.onerror = (error: Event) => {
            console.error('WebSocket error:', error);
        };
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserInput(e.target.value);
    };

    // const filesToBase64 = async (file: File): Promise<string> => {
    //     const full = await new Promise<string>((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const base64String = reader.result as string;
    //             resolve(base64String);
    //         };
    //         reader.onerror = (error) => {
    //             reject(error);
    //         };
    //         reader.readAsDataURL(file);
    //     });
    //
    //     return full.split(',')[1];
    // };



    const onSubmit = async (e: any) => {
        if (e.shiftKey) {
            return;
        }
        setLoading(true);
        let thread = getMessage();


        let messageThread: messageThread = {
            role: 'user',
            content: files!.length > 0 ? [
                {
                    type: 'text',
                    text: userInput,
                },
            ] : userInput,
        };

        for (const file of files!) {
            let response = await uploadFile(id, file);
            console.log(response);
            let message: messageItem = {
                type: 'image_url',
                // source: {
                //     type: 'base64',
                //     media_type: file.type,
                //     data: await filesToBase64(file),
                // },
                image_url: {
                    url: response.url,
                },
            };
            if (typeof messageThread.content !== "string") {
                // @ts-ignore
                messageThread.content.push(message);
            }
        }

        // insert user message into the chat
        thread.push(messageThread);
        updateData(thread);
        try {
            updateData(thread);

            await startConnection();

        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const handleDrop = async (e: any) => {
        e.preventDefault();
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        if (e.dataTransfer.items.length === 0) {
            return;
        }
        const droppedFiles: File[] = Array.from(e.dataTransfer.files);
        for (const file of droppedFiles) {
            if (!allowedTypes.includes(file.type)) {
                message.error('Invalid file type');
                return;
            } else {
                if (files!.length >= 5) {
                    message.error('You can only upload 5 images');
                    return;
                }
                setFiles((prevFiles) => [...prevFiles!, file]);
            }
        }
    };

    const handlePaste = (event: { clipboardData: { items: any; }; }) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.indexOf('image') !== -1) {
                const blob = item.getAsFile();
                const file = new File([blob], 'pasted-image.png', { type: 'image/png' });
                setFiles((prevFileList) => [...prevFileList!, file]);
            }
        }
    };


    return (
        <div>
            <div className="file-container">
                {files &&
                    files.map((file, index) => (
                        <img
                            key={index}
                            src={URL.createObjectURL(file)}
                            alt="preview"
                            style={{ width: 50, height: 50, objectFit: 'cover', margin: '1%' }}
                        />
                    ))}
            </div>
            <Spin spinning={loading} delay={500}>
                <Space.Compact style={{ width: '100%' }}>
                    <TextArea
                        onChange={onChange}
                        placeholder="Type your message here"
                        style={{ height: '80%', resize: 'none', minHeight: 80 }}
                        value={userInput}
                        onPressEnter={onSubmit}
                        onDrop={handleDrop}
                        onPaste={handlePaste}
                    />
                </Space.Compact>
            </Spin>
        </div>
    );
};

export default ChatBox;
