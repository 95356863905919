// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, {AxiosError} from "axios";
import {postData} from "./axios";

type loginResponse = {
    token?: string;
    expiry?: string;
};
export const loginApi = async (email: string | undefined, password: string | undefined): Promise<loginResponse> => {
    try {
        const data : Object = {
            email:email,
            password:password
        }
        const response: any = await postData("login",data)
        let token =
            {
                token: response.token,
                expiry: response.expiry
            }
            localStorage.setItem("token",JSON.stringify(token))
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


export const registerApi = async (email: string | undefined, password: string | undefined, invite_code: string | undefined): Promise<loginResponse> => {
    try {
        const data : Object = {
            email:email,
            password:password,
            invite_code:invite_code
        }
        const response: any = await postData("register",data)
        let token =
            {
                token: response.token,
                expiry: response.expiry
            }
        localStorage.setItem("token",JSON.stringify(token))
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

