import {getData, postData, postFormData} from "./axios";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";

export type messageItem = {
    type: string, // base64, image_url
    text?: string,
    // source?: {
    //     type: string,
    //     media_type: string,
    //     data: string,
    // }
    image_url?: {
        url: string
    } // for chat-gpt
}

export type messageThread = {
    role: string,
    content: Array<messageItem> | string
}

export type chat = {
    chat_id: string
    last_updated: string;
    conversation: Array<messageThread>,
    type: string
};
export const getAllChats = async (pageNumber:number): Promise<Array<chat>> => {
    try {
        const response: any = await getData(`get_chat_history?page=${pageNumber}`)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


type createChatResponse = {
    chat_id: string;
}
export const createChat = async (model:string): Promise<createChatResponse> => {
    try {
        const response: any = await getData(`start_chat?model=${model}`)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export type getChatResponse = {
    chat_id: string,
    conversation: Array<messageThread>
    type: string
}
export const getAChatConversation = async (chatId : string): Promise<getChatResponse> => {
    try {
        const response: any = await postData("get_chat",{chat_id: chatId})
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


export const sendMessage = async (chatId: string | undefined, messages: messageItem[]): Promise<messageThread> => {
    try {
        const response: any = await postData("send_message",{
            chat_id: chatId,
            message: messages
        })
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export interface uploadFileResponse {
    url: string
}
export const uploadFile = async (chatId: string | undefined, file: File): Promise<uploadFileResponse> => {
    try {
        let formData = new FormData();
        formData.append('chat_id', chatId!);
        formData.append('file', file);
        const response: any = await postFormData("upload_file",formData)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


