import React from 'react';
import { Layout, theme } from 'antd';
import './chat.css'
import ChatHistory from "./components/chatHistory";
import ChatSide from "./components/chatSide";
import {useParams} from "react-router-dom";

const { Content, Sider } = Layout;



const Chat: React.FC = () => {
    const {
        token: { colorBgContainer},
    } = theme.useToken();

    const { id } = useParams();


    return (
        <>
        <Layout className="box">
            <Layout>
                <Sider width={300} style={{ background: colorBgContainer, overflowY:"auto" }}>
                    <ChatHistory />
                </Sider>
                <Layout style={{ padding: '0 0' }}>
                    <Content
                        style={{
                            padding: 0,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <ChatSide id={id} />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
        </>
    );
};

export default Chat;