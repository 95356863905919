import React from 'react';
import {Button, Form, type FormProps, Input, notification} from 'antd';
import './login.css';
import {loginApi} from "../../axios/userService";
import { useNavigate } from 'react-router-dom';

type FieldType = {
    email?: string;
    password?: string;
};
// eslint-disable-next-line react-hooks/rules-of-hooks



const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [api] = notification.useNotification();
    const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
        console.log('Success:', values);

        try {
            const result = await loginApi(values.email, values.password)
            if (result.token){
                navigate("/")
            }

        }
        catch (err : unknown){
            api['error']({
                message: 'Register Failed',
                description:
                    'Please check your email and password',
            });
        }

    };
    return (

    <>
        <div className="loginBox">
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                    <div style={{ margin: 10 }}/>
                    <Button type="primary" href="/register">
                        Register
                    </Button>
                </Form.Item>
            </Form>
        </div>
    </>
);}

export default Login;